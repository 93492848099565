import React from 'react';
import styled from 'styled-components';

const Container=styled.div`
display:flex;
        align-items: center;
        justify-content: center;
        padding-bottom:10px;
`;

const Image=styled.img`
width:100%;
`;

const Container1=styled.div`
display:flex;
`;

const Content1=styled.div`
flex:1;
display:flex;
justify-content: center;
padding:40px;
line-height: 3vh;
`;
const Title1=styled.div`
display:flex;
justify-content: center;
font-size:40px;
font-family: 'Libre Bodoni', serif;

`;

const BedFacilities = () => {
  return (
    <div>
            <Container>
          <Image src='images/universal.jpg'/>
        </Container>
        <Title1>Bed Facilities</Title1><br/>
        
        <Container1>
          <Content1><p> Our Hospital has the following type of Rooms available for patients occupency. The tarif is comparitively moderate and affordable.<br/>
            <br/> <i class="fas fa-hand-point-right"></i>  &nbsp; Common ward <br /> <br /> <i class="fas fa-hand-point-right"></i>  &nbsp; Single Room A/C <br /><br /><i class="fas fa-hand-point-right"></i>  &nbsp;  Single Room Non-A/C <br /> <br /><i class="fas fa-hand-point-right"></i>  &nbsp;  Twin sharing Room <br /> <br /><i class="fas fa-hand-point-right"></i>  &nbsp;  Deluxe Room<br /><br /> <i class="fas fa-hand-point-right"></i>  &nbsp; Suite Room<br /><br /><i class="fas fa-hand-point-right"></i>  &nbsp;  Day care  </p>
    </Content1>
        </Container1>
    </div>
  );
}

export default BedFacilities;
