import React from 'react';
import styled from 'styled-components';
import'./footer.css'
import HomeIcon from '@mui/icons-material/Home';
import CallIcon from '@mui/icons-material/Call';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EmailIcon from '@mui/icons-material/Email';
import Maps from './Maps';
import { mobile } from './mobile';
import { Laptop } from './laptop';




const Container=styled.div`
background-color: #35AAC0;
height:40vh;
position:relative;
display:flex;
width:100%;
${mobile({ flexDirection: "column"})}
${Laptop({height:"45vh"})}
`;
const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  ${Laptop({flex:"0.5", padding:"0px"})}
`;
const LeftCenter=styled.div`
flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  ${mobile({ backgroundColor: "#35AAC0"})}
  ${Laptop({flex:"0.1", padding:"2px"})}
`;

const Right = styled.div`
  flex:1;
  padding: 20px;
  ${mobile({ backgroundColor: "#35AAC0"})};
  ${Laptop({flex:"0.5", padding:"2px"})}
`;

const Center=styled.div`
flex: 1;
padding: 20px 10px;
${mobile({ backgroundColor: "#35AAC0",padding:"10px 5px"})};
${Laptop({flex:"0.5"})}
`;
const LogoContainer=styled.div`

`;
const Image=styled.img`
height:200px;
${Laptop({height:"100px"})}
`;

const InfoContainer=styled.div`
${Laptop({fontSize:"17px"})}
`;
const Title=styled.div`
color:red;
font-size:30px;
margin-left:45px;
font-family: sans-serif;
${Laptop({marginLeft:"0px"})}
`;
const ContactItem = styled.div`
 margin-bottom:25px;
  display: flex;
  align-items: center;
  letter-spacing:3px;
  text-transform: none;
  ${mobile({ letterSpacing:"2px"})};
  ${Laptop({letterSpacing:"0px"})}
`;
const ContactItem1 = styled.div`
 margin-bottom:25px;
  display: flex;
  align-items: center;
  letter-spacing:3px;
  text-transform: none;
  ${mobile({ letterSpacing:"1px"})}
`;
const Title1=styled.div`
padding:20px;
font-size:30px;


`;
const Container2=styled.div`


`;



const Footer = () => {
  return (
    <div>
      <div class="header">
<div class="inner-header flex">
</div>
<div>
<svg class="waves" 
viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
<defs>
<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
</defs>
<g class="parallax">
<use href="#gentle-wave" x="48" y="0" fill="#35aac0" opacity="0.7" />
<use href="#gentle-wave" x="48" y="3" fill="#35aac0" opacity="0.5" />
<use href="#gentle-wave" x="48" y="5" fill="#35aac0" opacity="0.3" />
<use href="#gentle-wave" x="48" y="7" fill="#35AAC0" />
</g>
</svg>
</div>
</div>

<Container>
<Left>
      <Title1>ADDRESS:</Title1>
        <ContactItem>
          <HomeIcon style={{marginRight:"10px"}}/><pre>370, Jari Kondalampatty,
            <br/><br/>Near Kondalampatty Roundana,
            <br/><br/>Salem - 636 010.</pre>
        </ContactItem>
        </Left>
        <LeftCenter>
        <Title1>CONTACT:</Title1>
        <ContactItem>
          <CallIcon style={{marginRight:"10px"}}/> 0427 2273477, 2273577.
         
        </ContactItem>
        <ContactItem>
        <SmartphoneIcon style={{marginRight:"10px"}}/> +91-9597194111
        </ContactItem>
        <ContactItem1>
          <EmailIcon style={{marginRight:"10px"}} />universalcancerhospital@gmail.com
        </ContactItem1>
        </LeftCenter>
       <Center>
        <Maps /> 
        <Container2>
      
      </Container2>
       </Center>
        <Right>
        <LogoContainer>
        <Image src="images\logo1.png"></Image>
        <InfoContainer>
        <Title>Accreditation</Title>
        <br/>
        <p>Universal Hospital has received Entry Level Accreditation Certification from National Accreditation Board for Hospitals & Healthcare Providers (NABH)</p>
        </InfoContainer>
        </LogoContainer>
      </Right>
</Container>


    </div>
  );
}

export default Footer;
