import React from 'react';
import './Slider1.css';

const Slider1 = () => {
  return (
    <div>
          <div id="slider">
<figure>
<img className='imageslider' src="images/SKT_1301.JPG"/>
<img className='imageslider'src="images/SKT_1303.JPG"/>
<img className='imageslider'src="images/DSC_0052.JPG"/>
<img className='imageslider'src="images/SKT_1307.JPG"/>
<img className='imageslider'src="images/Moulding-a-RT-mask2.jpg"/>

</figure>
</div>
    </div>
  );
}

export default Slider1;
