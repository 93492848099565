import React from 'react';
import styled from 'styled-components';
import AppointMent from './AppointMent';
import { mobile } from './mobile';
import './HomeContent.css'
import { Laptop } from './laptop';


const Container=styled.div`
display: flex;
height: 60vh;
transition: all 0.3s ease-out;
align-items: center;
background: linear-gradient(90deg, rgba(211,244,10,1) 0%, rgba(197,255,170,0.804359243697479) 35%, rgba(0,212,255,1) 100%);
// background: bisque;
border-style: solid;
${mobile({ height:"40vh", backgroundColor:"#f2f2f2"})};
${Laptop({height:"50vh"})},

`;
const Left=styled.div`
flex:0.5;

`;
const Image=styled.img`
height:450px;
${mobile({ height:"15vh"})};
${Laptop({height:"20vh"})}

`;
const Slide=styled.div`

${mobile({position: "fixed",
  top: "31vh",
  zIndex:"1", 
  left:"0"})}
`;

const Right =styled.div`
flex:2;
${mobile({flex:"1",})}
`;
const Text =styled.div`
positon:relative;
color: rgb(10, 10, 58);
font-size:100px;
text-align: justify;
text-justify: inter-word;
font-family: 'Libre Bodoni', serif;
${mobile({fontSize:"13px",marginTop:"30px" })}
${Laptop({fontSize:"20px",marginTop:"15px"})}

`;
const Content =styled.div`
positon:absolute;
margin-top:auto;
font-size:30px;
padding-right: 30px;
padding-bottom: 30px;
font-family: 'Libre Bodoni', serif;
text-transform:none;
text-align: justify;
text-justify: inter-word;
${mobile({fontSize:"10px",paddingTop: "10px"})}
${Laptop({fontSize:"15px"})}
`;

const HomeContent = () => {
  return (
    <div>
    <Container>
      <Left>
    <Image src="images/mainlogo.png"></Image>
    </Left>
    <Right>
      <Text>
       Universal Hospital
       </Text>
       <Slide className='container2'>
         
        <AppointMent/>
       </Slide>
<Content>Ours is a 100 bedded Cancer & Super Specialty Corporate Hospital which was started in the year 2016. We at, Universal Cancer Hospital offer world class comprehensive cancer care treatment in Radiation Oncology, Surgical Oncology and Medical Oncology under one roof. <br />
<br />Ours is the first cancer hospital at Salem bearing Corporate style infrastructure accompanying with HD advanced comprehensive cancer care equipments with full fledged Consultants for all types of cancer treatments. <br />
<br />We also have Multi-super specialties with team of very good experts in their respective fields.

</Content>
    </Right>
    </Container>
    </div>
  );
}

export default HomeContent;
