import React from 'react';
import './HospitalServices.css';
import styled from 'styled-components';
import { Link } from 'react-router-dom';


const Title=styled.h1`
display:flex;
aling-items:center;
justify-content:center;
background:  #b2b2b2;
letter-spacing:2px;
padding:10px;
margin-top: 50px;
`;
const Underline=styled.div`
height: 04px;
width: 5rem;
margin-bottom:10px;
background-color: #f76c2f;
margin-top:10px;
margin-bottom: 50px;
`;
const Container2=styled.div`
display: flex;
justify-content: center; 
`;


const HospitalServices = () => {
  return (
    <div>
      <section class="services1" id="services1">

<Title>Our Services:</Title>
<Container2><Underline/></Container2>
<br/>
<div class="box-container">

    <div class="box">
    <Link to='/MasterCheck'>
        <i class="fas fa-notes-medical"></i>
        <h3>Master Health Checkup</h3>
        </Link>
        
       
    </div>

    <div class="box">
       
       <Link to='/AmbulanceService'> <i class="fas fa-ambulance"></i>
        <h3>24/7 Ambulance Service</h3>
        </Link>
        
      
    </div>

    <div class="box">
    <Link to='/DoctorProfile'>
        <i class="fas fa-user-md"></i>
        <h3>Expert Doctors Panel</h3>
        </Link>
    </div>

    <div class="box">
    <Link to='/MedicalCamp'>
        <i class="fas fa-pills"></i>
        <h3>Speciality Medical Camps</h3>
        </Link>
        
    </div>

    <div class="box">
    <Link to='/BedFacilities'>
        <i class="fas fa-procedures"></i>
        <h3>bed facility</h3>
        </Link>
        
        
    </div>

    <div class="box">
    <Link to='/MedicalPackages'>
        <i class="fas fa-heartbeat"></i>
        <h3>Speciality Medical Packages</h3>
        </Link>
        
    </div>

</div>

</section>
    </div>
  );
}

export default HospitalServices;
