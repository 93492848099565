import React from 'react';

const MedicalDisciplines = () => {
  return (
    <div>
    helo jii  
    </div>
  );
}

export default MedicalDisciplines;
