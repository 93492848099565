export const MenuItems = [
  {
    title: 'Facilities',
    path: '/Facilities',
    cName: 'dropdown-link'
  },
  {
    title: 'Insurance',
    path: '/Insurance',
    cName: 'dropdown-link'
  },

];
export const Special = [
  
  {
    title: 'MultiSpeciality',
    path: '/Specialities',
    cName: 'dropdown-link'
  },
  {
    title: 'Cancer Speciality',
    path: '/CancerHospital',
    cName: 'dropdown-link'
  },
];
export const About = [
  {
    title: 'Events',
    path: '/Events',
    cName: 'dropdown-link'
  },
  {
    title: 'Our Journey',
    path: '/OurJourney',
    cName: 'dropdown-link'
  },
  {
    title: 'Doctor Profile',
    path: '/DoctorProfile',
    cName: 'dropdown-link'
  },

];
