import { css } from "styled-components";

export const Laptop = (props) => {
  return css`
    @media (min-width: 490px) and (max-width: 1440px){
      ${props}
    }
  `;
};
  


