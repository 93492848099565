import React from 'react';
import './DoctorProfile.css';
import styled from 'styled-components';

const Container=styled.div`
background-color:white;
padding-left:00px;
`;
const Header=styled.h1`
background:  #b2b2b2;
text-align: center;
padding:25px;
padding-top:30px;
letter-spacing: 0.5rem;
`;

const DoctorProfile = () => {
  return (
    <div>
      <Header>OUR DOCTORS</Header>
      <Container>
      <figure class="snip1515">
        
  <div class="profile-image"><img src='\images\Senthilkumar.jpeg' alt="sample47" /></div>
  <figcaption>
    <h3>Dr.SENTHILKUMAR M.D,</h3><br />
    <h3>GENERAL MEDICINE</h3>
    
    
  </figcaption>
</figure>
<figure class="snip1515">
  <div class="profile-image"><img src="\images\Vidhya.jpeg" alt="sample83" /></div>
  <figcaption>
    <h3>Dr.VIDHYA <br /> M.B.B.S,MD(RT),DM(ONCO)</h3><br />
    <h3>MEDICAL ONCOLOGY</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\boy-dr.jpg' alt="sample47" /></div>
 <figcaption>
    <h3>Dr.G.M.JAGADEESAN M.S,MCH</h3><br />
    <h3>SURGICAL ONCOLOGIST</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\Sathish.jpeg' alt="sample47" /></div>
  <figcaption>
    <h3>Dr.Sathishkumar <br /> D.ORTHO, DNB</h3><br />
    <h3>ORTHO SURGEON</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\boy-dr.jpg' alt="sample47" /></div>
  <figcaption>
    <h3>Dr.Selvakumar M.S(Ortho)</h3><br />
    <h3>ORTHO SURGEON</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\Kalaiselvi.jpeg' alt="sample47" /></div>
  <figcaption>
    <h3>Dr.KALAISELVI M.D, DGO</h3><br />
    <h3>GYNAECOLOGIST</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\Kumaresan.jpeg' alt="sample47" /></div>
 <figcaption>
    <h3>Dr.KUMARESAN MBBS, DA</h3><br />
    <h3>ANAESTHETIST</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\boy-dr.jpg' alt="sample47" /></div>
  <figcaption>
    <h3>Dr.ANBARASAN <br /> MBBS,MD, DA</h3><br />
    <h3>ANAESTHETIST</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\Arunkumar.jpeg' alt="sample47" /></div>
 <figcaption>
    <h3>DR.ARUNKUMAR M.D.S</h3><br />
    <h3>DENTIST AND FACIO MAXILLARY SURGEON</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\Mahendran.jpeg' alt="sample47" /></div>
  <figcaption>
    <h3>DR.MAHENDRAN M.S,</h3><br />
    <h3>ENT</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\Anjankumar.jpeg' alt="sample47" /></div>
 <figcaption>
    <h3>DR.ANJAN KUMAR M.B.B.S, DLO </h3><br />
    <h3>ENT</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\boy-dr.jpg' alt="sample47" /></div>
 <figcaption>
    <h3>DR.BALASUBRAMANIAM M.S,M.CH</h3><br />
    <h3>NEURO SURGEON</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\Manikandan.jpeg' alt="sample47" /></div>
 <figcaption>
    <h3>DR.MANIKANDAN M.S, M.Ch</h3><br />
    <h3>PEDIATRIC/GENERAL SURGEON</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\boy-dr.jpg' alt="sample47" /></div>
 <figcaption>
    <h3>DR.BALAJI M.D, DM</h3><br />
    <h3>PEDIATRIC/NEONALOGIST</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\Pugazhenthi.jpeg' alt="sample47" /></div>
<figcaption>
    <h3>DR.PUGAZHANTHI M.S, M.CH</h3><br />
    <h3>UROLOGIST</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\boy-dr.jpg' alt="sample47" /></div>
<figcaption>
    <h3>DR.SIVARAMAN M.S, MCH</h3><br />
    <h3>PLASTIC SURGEON</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\Vijaykannan.jpeg' alt="sample47" /></div>
<figcaption>
    <h3>DR.VIJAYAKANNAN M.S, MCH</h3><br />
    <h3>PLASTIC SURGEON</h3>
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\boy-dr.jpg' alt="sample47" /></div>
 <figcaption>
    <h3>DR.KARTHIKEYAN MD(Psy)</h3><br />
    <h3>PSYCHIATRIST</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\boy-dr.jpg' alt="sample47" /></div>
 <figcaption>
    <h3>DR.DHANARAJSEKAR MD(Psy)</h3><br />
    <h3>PSYCHIATRIST</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\Vasudevan.jpeg' alt="sample47" /></div>
<figcaption>
    <h3>DR.VASUDEEVAN M.B.B.S, DTCD</h3><br />
    <h3>PULMONOLOGIST</h3>
   
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\boy-dr.jpg' alt="sample47" /></div>
 <figcaption>
    <h3>DR.SIVASUBRAMANIYAM <br />M.D, DM</h3><br />
    <h3>CARDIOLOGIST</h3>
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\Rajinikanth.jpeg' alt="sample47" /></div>
  <figcaption>
    <h3>DR.RAJINIKANTH B.P.T</h3><br />
    <h3>PHYSIOTHERAPIST</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\Anbuvijaykumar.jpeg' alt="sample47" /></div>
 <figcaption>
    <h3>DR.ANBUVIJAYKUMAR M.S.F.M.A.S</h3><br />
    <h3>GASTRO LAPROSCOPHY SURGEON</h3>
    
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\Jeyaprakash.jpeg' alt="sample47" /></div>
 <figcaption>
    <h3>DR.JAYAPRAKASH M.S(Opth)</h3><br />
    <h3>OPHTHALMOLOGIST</h3>
  </figcaption>
</figure>
<figure class="snip1515">
<div class="profile-image"><img src='\images\Hentry.jpeg' alt="sample47" /></div>
  <figcaption>
    <h3>DR.HENTRY SAMUVEL M.B.B.S</h3><br />
    <h3>Duty Medical Officer</h3>
    
  </figcaption>
</figure>

</Container>
    </div>
  );
}

export default DoctorProfile;

        


