import React from 'react';

const ModernHospitalPlanning = () => {
  return (
    <div>
      
    </div>
  );
}

export default ModernHospitalPlanning;
