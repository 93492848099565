import React from 'react';
import './Slider1';

const Slider = () => {
  return (
    <div>
      <div id="slider">
<figure>
<img className='imageslider' src="images/Image May.jpg"/>
<img className='imageslider'src="images/xray.jpg"/>
<img className='imageslider'src="images/ultrasound-scan.jpg"/>
<img className='imageslider'src="images/Image Mar.jpg"/>
<img className='imageslider'src="images/x-ray.png"/>
<img className='imageslider'src="images/CT.jpg"/>
</figure>
</div>

    </div>
  );
}

export default Slider;

