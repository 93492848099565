import React from 'react';
import styled from 'styled-components';
import { mobile } from '../mobile';
import './contactus.css'
const Container=styled.div`
display:flex;
justify-content:center;
padding-top: 100px;
${mobile({ display:"none" })}
`;

const Container2=styled.div`
padding-top: 40px;
padding-left:8px;

`;



const myHTML = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3907.859495009978!2d78.12487531533738!3d11.633360545970778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babef4f2f3563f7%3A0x231258e2361ba37d!2sUniversal%20Hospitals!5e0!3m2!1sen!2sin!4v1651505055225!5m2!1sen!2sin" width="1000" height="800" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;
const myHTML2 = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3907.859495009978!2d78.12487531533738!3d11.633360545970778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babef4f2f3563f7%3A0x231258e2361ba37d!2sUniversal%20Hospitals!5e0!3m2!1sen!2sin!4v1651505055225!5m2!1sen!2sin" width="350" height="500" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;


const ContactUs = () => {
  return (
    <div>
      <Container>      
        <div dangerouslySetInnerHTML={{ __html: myHTML }} />;
        </Container>
        <Container2>
      <div className='container2'>
      <div dangerouslySetInnerHTML={{ __html: myHTML2 }} />;
      </div>
      </Container2>
    </div>
  );
}

export default ContactUs;
