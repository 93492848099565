import React from 'react';
import './Addbar.css';
import styled from 'styled-components';
import CallIcon from '@mui/icons-material/Call';
import { SmartphoneTwoTone } from '@mui/icons-material';





const Container=styled.div`
background: LightGray;

`;


const Addbar = () => {
  return (
    <div>
        <Container>
      <div class="scrolling">
		<pre>UNIVERSAL  HOSPITAL  <pre1> Live Life Longer...</pre1><pre3>( <pre2>A  Unit  of  </pre2>Universal Cancer Hospital Limited)   <CallIcon /> 0427 - 2273477, 2273577,   <SmartphoneTwoTone style={{marginRight:"10px"}}/>+91-9597194111</pre3></pre>
	</div>
    </Container>
    </div>
  );
}

export default Addbar;
