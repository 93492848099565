import { Title } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';

const Container=styled.div`
display:flex;
        align-items: center;
        justify-content: center;
        padding-bottom:10px;
`;

const Image=styled.img`
width:100%;
`;

const Container1=styled.div`
display:flex;
`;

const Content1=styled.div`
flex:1;
display:flex;
justify-content: center;
padding:40px;
line-height: 300%;

`;
const Title1=styled.div`
display:flex;
justify-content: center;
font-size:40px;
font-family: 'Libre Bodoni', serif;
`;
const MedicalCamp = () => {
  return (
    <div>
            <Container>
          <Image src='images/universal.jpg'/>
        </Container>
        <Title1>Speciality Medical Camps</Title1><br/>
        
        <Container1>
         
         <Content1><p><i class="fas fa-hand-point-right"></i>  &nbsp;  Speciality medical camps are regularly conducted periodicaly at various places. <br /> 
         <i class="fas fa-hand-point-right"></i>  &nbsp; They are Cardio camp, ENT camp, Diabetic camp, Orthopedic Camp, Dermatology camp, Pediatric Camp & OG camp.<br /> 
         <i class="fas fa-hand-point-right"></i>  &nbsp; We are regularly conducting awarness programs on the above health matters to educational Institutions, Philathraphic institutions, Business establishment. <br />
         <i class="fas fa-hand-point-right"></i>  &nbsp;  Municipalities, Corporation, Government institutions and Industrial establishments etc., 
          <br /><i class="fas fa-hand-point-right"></i>  &nbsp; A part from the above, we do conduct CMCHIS program camps every month at rural health centers as advised by Tamilnadu state Government.</p>
    </Content1>
        </Container1>
    </div>
  );
}

export default MedicalCamp;
