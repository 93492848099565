import React from 'react';
import HospitalInfo from '../HospitalInfo';
import HomeContent from '../HomeContent';
import styled from 'styled-components';
import HospitalServices from '../HospitalServices';
import HomeVision from '../HomeVision';
import Footer from '../Footer';
import Maps from '../Maps';
import { mobile } from '../mobile';


const Container=styled.div`
display:flex;
        align-items: center;
        justify-content: center;
        
       
`;


const Image=styled.img`
width:100%;


`;


const Home = () => {
  return (
    <div>
    <Container>
          <Image src='images/universal.jpg'/>
        </Container>
    <HomeContent />
    <HomeVision/>
    <HospitalInfo />
    <HospitalServices/>
  {/* <Maps/> */}
 
  </div>
  );
}

export default Home;

