import React from 'react';
import styled from 'styled-components';
import { Laptop } from '../laptop';
import { mobile } from '../mobile';

const Container=styled.div` 
display:flex;
align-items: center;
justify-content: center; 
${mobile({ flexWrap: "wrap"})};  
`;
const Image=styled.img`
height:700px;
width:100%;  
${mobile({ height:"50vh",
  width:"30vh",marginTop:"10px" })}; 
`;

const Container1=styled.div`
display:flex;
${mobile({ flexWrap: "wrap"})};
`;
const Content=styled.div`;
margin-left:150px;
margin-top:50px;
${mobile({ marginLeft:"0px",
  marginTop:"10px"})};
`;
const Header=styled.h1`
display:flex;
justify-content: center;
margin-top: 55px;
font-family: 'Staatliches', cursive;
${mobile({marginLeft: "20px"})}
`;
const Header1=styled.h2`
position:relative;
padding-bottom:40px;
margin-left: 65px;
font-family: 'Libre Bodoni', serif;
${Laptop({marginLeft: "50px"})},
`;
const Content1=styled.div`
height:20vh;
margin-left: 30vh;
padding:50px;
margin-top: 55px;
font-family: 'Acme', sans-serif;
border-style: solid;
  border-color: coral;
  line-height: 1.6;
  ${mobile({ marginLeft:"0px",
  marginTop:"5vh",padding:"20px"})};
`;
const Image1=styled.img`
height:50%;
width:20vh;
margin-left: 80px;
`;
const DoctorName=styled.div`
font-family: 'Roboto', sans-serif;
font-size:20px;
padding-top:20px;
margin-left: 30px;
position:absolute;

`;

const Psychiatry = () => {
  return (
    <div>
      <Container>
          <Image src='images/Psychiatry-1.jpg'/>
        </Container>
        <Header>
         <h3>PSYCHIATRY</h3>
         </Header> 
        <Container1>
          <Content>
          <Header1>MEET OUR EXPERT</Header1>
          <Image1 src='\images\boy-dr.jpg'/>
          <DoctorName>DR.KARTHIKEYAN MD(Psy)</DoctorName>
         
          </Content>
          
          <Content1>
            <p>Our team experts in the department of Psychiatry are very good and their success rate is 100%.</p>
    </Content1>
        </Container1>
        </div>
  );
}

export default Psychiatry;
