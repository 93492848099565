import React from 'react';
import'./mastercheckup.css'
import styled from 'styled-components';
import { mobile } from '../mobile';

const Container=styled.div`
display:flex;
flex-direction: column;
margin-left: 100px;
margin-right: 100px;
${mobile({marginLeft: "50px",
  marginRight: "50px" })}
`;



const MasterCheck = () => {
  return (
    <Container>

   
    <div class="pricing">
 
    <div class="pricing__title">Master Health Checkup</div>
    <div class="pricing__grid">
      <div class="pricing__card pricing-card pricing-card_free">
        <div class="pricing-card__top">
          <div class="pricing-card__title">PLATINUM MASTER HEALTH CHECKUP</div>
          <div class="pricing-card__price">Rs.6500<span>/-</span></div>
        </div>
        <div class="pricing-card__body">
          <div class="pricing-card__pluses">
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">FILE</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">CBC</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">BLOOD SUGAR -F -PP</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">BLOOD GROUPING & TYPING</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">FASTING LIPID PROFILE </div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">UREA, CREATININE, ELECTROLYTES</div>
            </div>
          
          <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">URIC ACID</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">T3, T4, TSH</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">HBA1C</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">CALCIUM</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">LIVER FUNCTION TEST</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">URINE MICROALBUMINURIA</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">URINE ROUTINE </div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">STOOL COMPLETE ANALYSIS</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">PAP SMEAR</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">SEROLOGY</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">ECG</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">CHEST - XRAY</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">USG ABDOMEN</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">ECHO, TMT</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">PHYSICIAN OPINION</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">GYNAECOLOGIST OPINION</div>
            </div>
            </div>
        </div>
      </div>
      <div class="pricing__card pricing-card pricing-card_free">
        <div class="pricing-card__top">
          <div class="pricing-card__title">GOLD MASTER HEALTH CHECKUP</div>
          <div class="pricing-card__price">Rs.4500<span>/-</span></div>
        </div>
        <div class="pricing-card__body">
          <div class="pricing-card__pluses">
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">FILE</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">CBC</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">BLOOD SUGAR -F -PP</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">BLOOD GROUPING & TYPING</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">FASTING LIPID PROFILE </div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">UREA, CREATININE</div>
            </div>
          
          <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">URIC ACID</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">T3, T4, TSH</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">HBA1C</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">URINE MICROALBUMINURIA</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">URINE ROUTINE </div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">STOOL COMPLETE ANALYSIS</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">SEROLOGY</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">ECG</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">CHEST - XRAY</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">USG ABDOMEN</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">PHYSICIAN OPINION</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">GYNAECOLOGIST OPINION</div>
            </div>
            </div>
        </div>
      </div>
      <div class="pricing__card pricing-card pricing-card_free">
        <div class="pricing-card__top">
          <div class="pricing-card__title">SILVER MASTER HEALTH CHECKUP</div>
          <div class="pricing-card__price">Rs.2500<span>/-</span></div>
        </div>
        <div class="pricing-card__body">
          <div class="pricing-card__pluses">
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">FILE</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">CBC</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">BLOOD SUGAR -F -PP</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">TOTAL CHOLESTEROL</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">UREA, CREATININE</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">TSH</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">URINE ROUTINE </div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">STOOL COMPLETE ANALYSIS</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">ECG</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">CHEST - XRAY</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">USG ABDOMEN SCREENING</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">PHYSICIAN OPINION</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">GYNAECOLOGIST OPINION</div>
            </div>
            </div>
        </div>
      </div>
      <div class="pricing__card pricing-card pricing-card_free">
        <div class="pricing-card__top">
          <div class="pricing-card__title">CANCER SCREENING MASTER HEALTH CHECKUP</div>
          <div class="pricing-card__price">Rs.5500<span>/-</span></div>
        </div>
        <div class="pricing-card__body">
          <div class="pricing-card__pluses">
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">CBC, BT, CT</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">BLOOD GROUPING & TYPING</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">SUGAR</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">UREA</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">CREATININE</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">LFT</div>
            </div>
          <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">PAP SMEAR</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">PSA</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">SEROLOGY</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">STOOL</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">ECG</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">CHEST - XRAY</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">ULTRASOUND</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">ECHO</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">GYNAECOLOGIST OPINION</div>
            </div>
            <div class="pricing-card__plus">
              <div class="pricing-card__plus-icon"><i class="material-icons">done_all</i></div>
              <div class="pricing-card__plus-text">ONCOLOGIST OPINION</div>
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>


</Container>
  );
}

export default MasterCheck;
