import React from 'react';
import Oncology from './Specialities/Oncology';

const CancerHospital = () => {
  return (
    <div>
      <Oncology/>
    </div>
  );
}

export default CancerHospital;
