import React from 'react';
import'./vission.css'
import styled from 'styled-components';
import { mobile } from './mobile';


const Container=styled.div`
display:flex;

${mobile({ display:"flex",	flexWrap: "wrap" })}

`;
const Underline=styled.div`
height: 04px;
width: 8rem;
margin-bottom:10px;
background-color: #f76c2f;
margin-top:10px;
`;
const Container2=styled.div`
display: flex;
justify-content: center; 
`;
const Title1=styled.h2`
text-align: center;
margin-top: 30px;
background:  #b2b2b2;
letter-spacing:2px;
`;


const Vission = () => {
  return (
    <div>
		<Title1>Mission,Vision,Quality Policy and Core Values</Title1>
	<Container2><Underline/></Container2>
        <Container>
      <figure class="card" tabindex="0">
		{/* <img class="card-image" src='\images\mission-logo.jpg' alt=""/> */}
		<div class="box">
			<div class="box-side-borders"></div>
			<blockquote class="text">“To be the first choice of excellance in comprehensive Cancer care, comprehensive Emergency and Trauma care. A Premier Health care provider in the region with world class standard.”</blockquote>
		</div>
		<figcaption>
			<cite class="cite">— OUR MISSION —</cite>
		</figcaption>
	</figure>
	<figure class="card dark" tabindex="0">
		{/* <img class="card-image" src='\images\vission-logo.jpg' alt=""/> */}
		<div class="box">
			<div class="box-side-borders"></div>
			<blockquote class="text">“Total Health care under one roof which is readily accessible, cost effective and affordable. Ensuring care beyond expectation.”</blockquote>
		</div>
		<figcaption>
			<cite class="cite">— OUR VISION —</cite>
		</figcaption>
	</figure>
    <figure class="card dark" tabindex="0">
		{/* <img class="card-image" src='\images\quality-logo.png' alt=""/> */}
		<div class="box">
			<div class="box-side-borders"></div>
			<blockquote class="text">“Total commitment to quality with compassion, Unique standards in every aspect with continual improvement,<br />
     and Consistent throughout.”</blockquote>
		</div>
		<figcaption>
			<cite class="cite">— QUALITY POLICY —</cite>
		</figcaption>
	</figure>
    <figure class="card" tabindex="0">
		{/* <img class="card-image" src='\images\values-logo.jpg' alt=""/> */}
		<div class="box">
			<div class="box-side-borders"></div>
			<blockquote class="text">“Care, Compassion, Commitment to quality. Teamwork, Trust and Respect for the Individual.
”</blockquote>
		</div>
		<figcaption>
			<cite class="cite">— CORE VALUES —</cite>
		</figcaption>
	</figure>
    
    </Container>
    </div>
  );
}

export default Vission;
