import React from 'react';
import './Button.css';
const Button = () => {
  return (
    <div>
      <a href='https://mocdoc.in/hospital/universal-hospital'>
<button className='btn'> Appointments</button>
</a>
    </div>
  );
}

export default Button;




