import React from 'react';
import styled from 'styled-components';

const Container=styled.div`
display:flex;
        align-items: center;
        justify-content: center;
        padding-bottom:10px;
`;

const Image=styled.img`
width:100%;
`;

const Container1=styled.div`
display:flex;
`;

const Content1=styled.div`
flex:1;
display:flex;
justify-content: center;
padding:40px;
line-height: 300%;
`;
const Title1=styled.div`
display:flex;
justify-content: center;
font-size:40px;
font-family: 'Libre Bodoni', serif;
`;

const MedicalPackages = () => {
  return (
    <div>
            <Container>
          <Image src='images/universal.jpg'/>
        </Container>
        <Title1>Medial Packages</Title1><br/>

        <Container1>
          <Content1><p> <br /> <i class="fas fa-hand-point-right"></i>  &nbsp; OG packages<br /> <br /> <i class="fas fa-hand-point-right"></i>  &nbsp; Ortho Packages <br /><br />  <i class="fas fa-hand-point-right"></i>  &nbsp;Cardio packages <br /><br />  <i class="fas fa-hand-point-right"></i>  &nbsp; Cancer packages </p>
    </Content1>
        </Container1>
    </div>
  );
}


export default MedicalPackages;
