import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { mobile } from '../mobile';

const Container=styled.div`
display:flex;
        align-items: center;
        justify-content: center;
        padding-bottom:10px;
`;

const Image=styled.img`
height:700px;
width:100%; 
`;

const Container1=styled.div`
display:flex;
align-items: center;
justify-content: center
`;
const Header=styled.h1`
text-align: center;
font-family: 'Staatliches', cursive;
`;
const Content1=styled.div`
padding:50px;
margin-top: 55px;
border-style: solid;
border-color: coral;
font-family: 'Acme', sans-serif;
${mobile({ lineHeight:"2vh"})};
`;
const AmbulanceService = () => {
  return (
    <div>
        
            <Container>
          <Image src='images/Ambulance.jpg'/>
        </Container>
        <Header>24/7 Ambulance Service</Header>
        <Container1>
          <Content1><p><i class="fas fa-hand-point-right"></i>  &nbsp; 24/7 Ambulace service is available all the 24 hrs. Any time when emergency call comes our fully equipped ACLS ambulance will arrive on the spot to rescue the patient. <br />  <br /><i class="fas fa-hand-point-right"></i>  &nbsp;This team is consist of ACLS certified persons. <br /> <br /><i class="fas fa-hand-point-right"></i>  &nbsp;  Our ambulance is fully equipped with ICU on wheels with fully trained staff / nurse. </p>
    </Content1>
        </Container1>
    </div>
  );
}

export default AmbulanceService;
