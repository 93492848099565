import './Specalities.css';
import styled  from 'styled-components';
import React from 'react';
import Slider from '../Slider';
import { Link } from 'react-router-dom';
import { mobile } from '../mobile';

const Container=styled.div`
display:flex;
justify-content: center;

`;
const Image=styled.img`
height:50vh;
width:120vh;
`;
const Container1=styled.div`
padding-left: 200px;
display:flex;
${mobile({paddingLeft: "20px" })};
`;

const Content1=styled.div`

flex:1;
`;
const Content2=styled.div`

flex:1;
padding-left:20px;
`;
const Title=styled.div`
text-align: center;
letter-spacing: 3px;
font-family: 'Libre Bodoni', serif;
font-size: 30px;
padding-bottom: 20px;

`;
const Specialities = () => {
  return (
    <div>
      <Container>
      <Slider/>
    </Container>
    <Title>A dedicated team of Multispeciality with full time Consultants</Title>
    <Container1>
   
      <Content1>
  <ol>
  <li><i class="fas fa-hand-point-right"></i><Link to='/Accident'>&nbsp;24x7 Accident and Emergency & Critical care</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/Medicine'>&nbsp;General medicine & Diabetology</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/LaproSurgery'>&nbsp;General surgery includes Laparoscopy Surgery</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/Trauma'>&nbsp;Comprehensive Trauma care</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/Orthopedics'>&nbsp;Orthopedics-Joint Replacement </Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/Gynaecology'>&nbsp;Obstetrics &  Gynaecology</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/Pediatric'>&nbsp;Neo-Natology  & Pediatrics</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/ChestMedi'>&nbsp;Pulmonology</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/Cardiology'>&nbsp;Cardiology</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/VascularSurgy'>&nbsp;Vascular Surgery</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/NeuroSurgy'>&nbsp;Neurology / Neuro Surgery</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/EntSurgy'>&nbsp;ENT Surgery</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/PediaSurgery'>&nbsp;Pediatric Surgery</Link></li><br/>
  </ol>
  </Content1>
  <Content2>
  <ol>
  <li><i class="fas fa-hand-point-right"></i><Link to='/DFSurgy'>&nbsp;Dental and Facio Maxillary Surgery</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/Urology'>&nbsp;Urology</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/Nephrology'>&nbsp;ephrology / Dialysis</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/Anaesthesiology'>&nbsp;Anaesthesiology & Pain clinic</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/Psychiatry'>&nbsp;Psychiatry</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/Radiology'>&nbsp;Radiology & Imaging Science</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/Ophthalmology'>&nbsp;Ophthalmology</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/Cosmetics'>&nbsp;Cosmetics & Dermatology</Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><Link to='/Physiotherapy'>&nbsp;Physiotherapy</Link></li><br/>
</ol>
</Content2>
    </Container1>
    </div>
  );
}

export default Specialities;
