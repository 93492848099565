import '../../App.css';
import HospitalServices from '../HospitalServices';

import React from 'react';

const Services = () => {
  return (
    <div>
      <HospitalServices/>
    </div>
  );
}

export default Services;

