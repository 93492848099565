import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import About from './components/pages/About';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Specialities from './components/pages/Specialities';
import ContactUs from './components/pages/ContactUs';
import Appointments from './components/pages/Appointments';
import Footer from './components/Footer';
import MedicalDisciplines from './components/MedicalDisciplines';
import OurJourney from './components/OurJourney';
import DoctorProfile from './components/DoctorProfile';
import CancerHospital from './components/CancerHospital';
import UniversalMultiSpecialityHospital from './components/UniversalMultiSpecialityHospital';
import ModernHospitalPlanning from './components/ModernHospitalPlanning';
import Events from './components/Events';
import Social from './components/social';
import Addbar from './components/Addbar';
import Accident from './components/Specialities/Accident';
import Medicine from './components/Specialities/Medicine';
import LaproSurgery from './components/Specialities/LaproSurgery';
import Trauma from './components/Specialities/Trauma';
import Orthopedics from './components/Specialities/Orthopedics';
import Obstetrics from './components/Specialities/Obstetrics';
import Gynaecology from './components/Specialities/Gynaecology';
import Pediatric from './components/Specialities/Pediatric';
import ChestMedi from './components/Specialities/ChestMedi';
import Cardiology from './components/Specialities/Cardiology';
import VascularSurgy from './components/Specialities/VascularSurgy';
import NeuroSurgy from './components/Specialities/NeuroSurgy';
import EntSurgy from './components/Specialities/EntSurgy';
import DFSurgy from './components/Specialities/DFSurgy';
import Urology from './components/Specialities/Urology';
import Oncology from './components/Specialities/Oncology';
import Nephrology from './components/Specialities/Nephrology';
import Psychiatry from './components/Specialities/Psychiatry';
import Radiology from './components/Specialities/Radiology';
import Pathology from './components/Specialities/Pathology';
import Anaesthesiology from './components/Specialities/Anaesthesiology';
import Ophthalmology from './components/Specialities/Ophthalmology';
import Cosmetics from './components/Specialities/Cosmetics';
import Physiotherapy from './components/Specialities/Physiotherapy';
import Facilities from './components/Facilities';
import Insurance from './components/Insurance';
import Equipments from './components/Equipments';
import PediaSurgery from './components/Specialities/PediaSurgery';
import MasterCheck from './components/Services/MasterCheck';
import AmbulanceService from './components/Services/AmbulanceService';
import MedicalCamp from './components/Services/MedicalCamp';
import BedFacilities from './components/Services/BedFacilities';
import MedicalPackages from './components/Services/MedicalPackages';
import Button from './components/Button';




function App() {
  return (
    <div>
    <Router>
  
      <Navbar />
      {/* <Addbar /> */}
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/About' component={About} />
        <Route path='/services' component={Services} />
        <Route path='/Specialities' component={Specialities} />
        <Route path='/contact-us' component={ContactUs} />
        <Route path='/Appointments' component={Appointments} />
        <Route path='/Medical Disciplines' component={MedicalDisciplines} />
        <Route path='/Events' component={Events} />
        <Route path='/Button' component={Button} />
        <Route path='/OurJourney' component={OurJourney} />
        <Route path='/DoctorProfile' component={DoctorProfile} />
        <Route path='/CancerHospital' component={CancerHospital} />
        <Route path='/UniversalMultiSpecialityHospital' component={UniversalMultiSpecialityHospital} />
        <Route path='/ModernHospitalPlanning' component={ModernHospitalPlanning} />
        <Route path='/Equipments' component={Equipments} />
        <Route path='/Facilities' component={Facilities} />
        <Route path='/Insurance' component={Insurance} />
        <Route path='/Accident' component={Accident} />
        <Route path='/Medicine' component={Medicine} />
        <Route path='/LaproSurgery' component={LaproSurgery} />
        <Route path='/Trauma' component={Trauma} />
        <Route path='/Orthopedics' component={Orthopedics} />
        <Route path='/Obstetrics' component={Obstetrics} />
        <Route path='/Gynaecology' component={Gynaecology} />
        <Route path='/Pediatric' component={ Pediatric} />
        <Route path='/ChestMedi' component={ChestMedi} />
        <Route path='/Cardiology' component={Cardiology} />
        <Route path='/VascularSurgy' component={VascularSurgy} />
        <Route path='/NeuroSurgy' component={NeuroSurgy} />
        <Route path='/EntSurgy' component={EntSurgy} />
        <Route path='/DFSurgy' component={DFSurgy} />
        <Route path='/Urology' component={Urology} />
        <Route path='/Oncology' component={Oncology} />
        <Route path='/Nephrology' component={Nephrology} />
        <Route path='/Psychiatry' component={Psychiatry} />
        <Route path='/Radiology' component={Radiology} />
        <Route path='/Pathology' component={Pathology} />
        <Route path='/Anaesthesiology' component={Anaesthesiology} />
        <Route path='/Ophthalmology' component={Ophthalmology} />
        <Route path='/Cosmetics' component={Cosmetics} />
        <Route path='/Physiotherapy' component={Physiotherapy} />
        <Route path='/PediaSurgery' component={PediaSurgery} />
        <Route path='/MasterCheck' component={MasterCheck} />
        <Route path='/AmbulanceService' component={AmbulanceService} />
        <Route path='/MedicalCamp' component={MedicalCamp} />
        <Route path='/BedFacilities' component={BedFacilities} />
        <Route path='/MedicalPackages' component={MedicalPackages} />
      </Switch>
      <Social />
       <Footer/> 
    </Router>
  
    </div> 
  );
}
export default App;