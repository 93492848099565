import styled  from 'styled-components';
import React from 'react';
import Slider from './Slider';
import { Link } from 'react-router-dom';

const Container=styled.div`
display:flex;
justify-content: center;
`;
const Container1=styled.div`
padding-left: 200px;
display:flex;

`;

const Content1=styled.div`
flex:1;
font-family: 'Libre Bodoni', serif;
`;
const Content2=styled.div`

flex:1;
padding-left:20px;
`;
const Title=styled.div`

text-align: center;
letter-spacing: 3px;
font-family: 'Libre Bodoni', serif;
font-size: 40px;
padding-bottom: 20px;
`;
const Specialities = () => {
  return (
    <div>
      <Container>
      <Slider />
    </Container>
    <Title>State of the art facilities available</Title>
    <Container1>
   
      <Content1>
  <ol>
  <li><i class="fas fa-hand-point-right"></i><Link to='/'><a>&nbsp; State of the art two major operation theaters with laminar airflow/Hepafilters</a></Link></li><br/>
  <li><i class="fas fa-hand-point-right"></i><a href="/">&nbsp;Fully Equiped ICU/SICU/NICU/ICCU/HDU with advanced monitors Dialysis ICU</a></li><br/>
  <li><i class="fas fa-hand-point-right"></i><a href="/">&nbsp;Advanced labour Ward with Tacometry</a></li><br/>
  <li><i class="fas fa-hand-point-right"></i><a href="/">&nbsp;Laproscopy</a></li><br/>
  <li><i class="fas fa-hand-point-right"></i><a href="/">&nbsp;Echo with TMT</a></li><br/>
  <li><i class="fas fa-hand-point-right"></i><a href="/">&nbsp;Digital Mammography</a></li><br/>
  <li><i class="fas fa-hand-point-right"></i><a href="/">&nbsp;24 hrs ACLS Ambulance</a></li><br/>
  <li><i class="fas fa-hand-point-right"></i><a href="/">&nbsp;Colposcopy</a></li><br/>
  <li><i class="fas fa-hand-point-right"></i><a href="/">&nbsp;4D ultrasound with color doppler</a></li><br/>
  <li><i class="fas fa-hand-point-right"></i><a href="/">&nbsp;24hrs Pharmacy</a></li><br/>
  <li><i class="fas fa-hand-point-right"></i><a href="/">&nbsp;24 hrs Advanced Laboratory Services</a></li><br/>
  <li><i class="fas fa-hand-point-right"></i><a href="/">&nbsp;SKANRAD 400 - High Frequency Diagnostic X-Ray system </a></li><br/>
  <li><i class="fas fa-hand-point-right"></i><a href="/">&nbsp;Ultrasound</a></li><br/>
  <li><i class="fas fa-hand-point-right"></i><a href="/">&nbsp;CT Scan - Siemens 32 Slice computerized tomography </a></li><br/>
  <li><i class="fas fa-hand-point-right"></i><a href="/">&nbsp;HDR Brachy Therapy-3D</a></li><br/>
  <li><i class="fas fa-hand-point-right"></i><a href="/">&nbsp;Advanced Radition therapy with linear digital accelerator-3D</a></li><br/>
  <li><i class="fas fa-hand-point-right"></i><a href="/">&nbsp;Colonoscopy</a></li><br/>
  </ol>
  </Content1>
  <Content2>
  <ol>
  
  {/* <li><a href="/DFSurgy"></a></li><br/>
  <li><a href="/Urology">Urology</a></li><br/>
  <li><a href="/Oncology">Oncology (Radiation, Medical & Surgical)</a></li><br/>
  <li><a href="/Nephrology">Nephrology / Dialysis</a></li><br/>
  <li><a href="/Anaesthesiology">Anaesthesiology & Pain clinic</a></li><br/>
  <li><a href="/Psychiatry">Psychiatry</a></li><br/>
  <li><a href="/Radiology">Radiology & Imaging Science</a></li><br/>
  <li><a href="/Ophthalmology">Ophthalmology</a></li><br/>
  <li><a href="/Cosmetics">Cosmetics & Dermatology</a></li><br/>
  <li><a href="/Physiotherapy">Physiotherapy</a></li><br/> */}
</ol>
</Content2>
    </Container1>
    </div>
  );
}

export default Specialities;
