import React from 'react';
import styled from 'styled-components';
import './HomeContent.css'

const Button=styled.div`


`;

const AppointMent = () => {
  return (
    <div>
      <a href='https://mocdoc.in/hospital/universal-hospital'>
<Button className='appointment'> Appointments</Button>
</a>
    </div>
  );
}

export default AppointMent;




