import React from 'react';
import styled from 'styled-components';
import { Laptop } from '../laptop';
import { mobile } from '../mobile';
import Slider1 from '../Slider1';
import './Cancerspeciality.css';

const Container=styled.div` 
display:flex;
align-items: center;
justify-content: center;   
${mobile({ flexWrap: "wrap"})};
`;
const Image=styled.img`
height:700px;
width:100%;  
`;

const Topic=styled.h3`

width:78%;
margin-left:22%;
border-bottom: none;

`;

const Container1=styled.div`
display:flex;
${mobile({ flexWrap: "wrap"})};
`;
const Content=styled.div`;
margin-left:50px;
margin-top:50px;
flex:0.5;
${mobile({ marginLeft:"0px",
  marginTop:"10px"})};
`;
const Header=styled.h3`
display:flex;
justify-content: center;
margin-top: 55px;
font-family: 'Staatliches', cursive;
flex-wrap: wrap;
padding-bottom:10px; 
font-size: 40px;
color: rgb(90, 6, 69);
${mobile({marginLeft: "20px"})}
`;
const Header1=styled.h2`
position:relative;
padding-bottom:40px;
margin-left: 95px;
font-family: 'Libre Bodoni', serif;
${Laptop({marginLeft: "50px"})},

`;

const Data=styled.div`
display:flex;
flex:2;

margin-top: 8px;
${Laptop({marginLeft: "50px"})}
${mobile({ marginTop: "10vh"})}
`;

const Content1=styled.div`
flex:1;
  line-height: 1.6;
  padding-left:15px;
`;

const Content2=styled.div`

  font-family: 'Acme', sans-serif;
  line-height: 1.6;
  margin-top: 70px;
  margin-right: 70vh;
  ${mobile({marginTop: "13vh"})}
`;


const Image1=styled.img`
height:40%;
width:40%;
margin-left: 120px;
${Laptop({marginLeft: "50px"})},
`;
const DoctorName=styled.div`
font-family: 'Roboto', sans-serif;
font-size:15px;
padding-top:20px;
margin-left: 65px;
position:absolute;
${Laptop({marginLeft: "10px"})},
`;


const Oncology = () => {
  return (
    <div>
      <Container>
          <Slider1 />
        </Container>
        <Header>
         A dedicated team of Cancer Speciality with full time Consultants
         </Header>
         <Topic>
         <div className='Oncology'>ONCOLOGY(Radiation, Medical & Surgical)<br /></div>

         <div className='Datacontent'><br />The team provides comprehensive cancer treatment and holistic care from the time of diagnosis to survival and appropriate treatment procedures.<br /></div>
         </Topic>
        <Container1>
          <Content>
          <Header1>MEET OUR EXPERT</Header1>
          <Image1 src='\images\Vidhya.jpeg'/>
          <DoctorName>Dr.VIDHYA 
            <br/>M.B.B.S,MD(RT),DM(ONCO)</DoctorName>
          </Content>
          <></>
          <Data>
          <Content1>
          <h3><p>Comprehensive Cancer care which includes : </p></h3> <br />
            <li>
            <i class="fas fa-hand-point-right"></i>&nbsp;Oncology Facility<br />
              <br />
              <i class="fas fa-hand-point-right"></i>&nbsp;Advanced Radiation Therapy with Linear Digital Accelerator - 3D<br />
              <br />
              <i class="fas fa-hand-point-right"></i>&nbsp;HDR Brachy Therapy -3D<br />
              <br />
              <i class="fas fa-hand-point-right"></i>&nbsp;Advanced Cancer Surgery & Therapy<br />
              <br />
              <i class="fas fa-hand-point-right"></i>&nbsp;Medical Oncology<br />
              <br /></li>
              <i class="fas fa-hand-point-right"></i>&nbsp; Cancer Screening<br />
              <br />
              </Content1>
              <Content2>
                <li>
                <i class="fas fa-hand-point-right"></i>&nbsp;Cancer Education <br />
              <br />
              <i class="fas fa-hand-point-right"></i>&nbsp;Cancer Detection<br />
              <br />
              <i class="fas fa-hand-point-right"></i>&nbsp;Cancer Counseling<br />
              <br />
              <i class="fas fa-hand-point-right"></i>&nbsp;Cancer Awareness<br />
              <br />
              <i class="fas fa-hand-point-right"></i>&nbsp;Cancer Health Planner<br />
              <br />
              <i class="fas fa-hand-point-right"></i>&nbsp;Cancer Palliative Care <br />
            </li>
    </Content2>
    </Data>
        </Container1>
        </div>
  );
}

export default Oncology;






