import React from 'react';
import styled from 'styled-components';


const Container=styled.div`

display: flex;
height: 40vh;
transition: all 0.3s ease-out;
align-items: center;
background: linear-gradient(90deg, rgba(211,244,10,1) 0%, rgba(197,255,170,0.804359243697479) 35%, rgba(0,212,255,1) 100%);
`;
const Left=styled.div`
flex:0.5;

`;
const Image=styled.img`
height:300px;
`;

const Right =styled.div`
flex:1;
`;

const Text =styled.div`
`;
const Content =styled.div`
positon:absolute;
margin-top:20px;
font-size:20px;
text-indent: 50px;
text-transform:none;
`;
const Para2 =styled.div`
text-indent: 50px;
`;

const AboutUs = () => {
  return (
    <div>
    <Container>
        
      <Left>
    <Image src="images/mainlogo.png"></Image>
    </Left>
    <Right>
      <Text>
      <h2>ABOUT-US</h2>
     </Text><br/>
<Content>Ours is a 100 bedded Cancer & Super Specialty Corporate Hospital which was 
started in the year 2016. We at, Universal Cancer Hospital offer world class 
comprehensive cancer care treatment in Radiation Oncology, Surgical Oncology and 
Medical Oncology under one roof. <br/><br/><Para2>Ours is the first cancer hospital at Salem bearing 
Corporate style infrastructure accompanying with HD advanced comprehensive cancer 
care equipments with full fledged consultants for all types of cancer treatments. 
We also have Multi-super specialties with team of very good experts in their
respective fields</Para2>
</Content>
    </Right>
    </Container>
    </div>
  );
}

export default AboutUs;
